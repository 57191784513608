import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { navItems } from '../sidebar/sidebar-data';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { CommonModule, NgForOf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ContainerName, SnackBar, StorageItemType } from 'src/app/shared/constants/storageitem';
import { LoginService } from 'src/app/shared/services/login.service';
import { CustomTranslationLoaderService } from 'src/app/custom-translation-loader.service';
import { environments } from 'src/environments/environment';
import { SnackbarComponent } from 'src/app/pages/snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { BikeStatusService } from 'src/app/shared/services/bikestatus.service';
import { Images } from 'src/app/shared/constants/images';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoginAsDialogComponent } from '../login-as-dialog/login-as-dialog.component';

interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface apps {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface quicklinks {
  id: number;
  title: string;
  link: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule, NgScrollbarModule, TablerIconsModule, MaterialModule,TranslateModule],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnDestroy{

  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  subscription: any = [];
  showFiller = false;
  userProfile: any;
  profileImagePath: string;
  languageList = [];
  selectedLanguage:any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  roleCode:any;

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: '/assets/images/flag/icon-flag-en.svg',
    },
    {
      language: 'Hindi',
      code: 'hi',
      icon: '/assets/images/flag/icon-flag-es.svg',
    },
    {
      language: 'Marathi',
      code: 'ma',
      icon: '/assets/images/flag/icon-flag-fr.svg',
    },
    {
      language: 'Gujarati',
      code: 'gu',
      icon: '/assets/images/flag/icon-flag-de.svg',
    },
  ];

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private storage: StorageService,
    private login: LoginService,
    private translateService : CustomTranslationLoaderService,
    private _snackBar: MatSnackBar,
    public readonly snackBar : BikeStatusService,
    private userService : UserService,
    private authService : AuthService,
    
  ) {

    this.userService.getUserDetails.subscribe(user => {
      this.userProfile = JSON.parse(JSON.parse(this.storage.getObject(StorageItemType.USER_PROFILE)));
      if (this.userProfile ) {
        const imagePath = this.userProfile?.imagePath == `${ContainerName.USER_PROFILES}/${Number(this.storage.getObject(StorageItemType.USER_ID))}/`;
        this.profileImagePath = imagePath ? Images.IMAGE_NOT_AVAILABLE : environments.cdnUrl + this.userProfile.imagePath
      }
    });
    this.roleCode = JSON.parse(JSON.parse(this.storage.getObject(StorageItemType.USER_PROFILE)));

    this.translate.setDefaultLang(this.userProfile?.languageCode);

    this.getLanguageList();
    this.loadGoogleTranslate();
  }

  loadGoogleTranslate() {
    const googleTranslateScript = document.createElement('script');
    googleTranslateScript.type = 'text/javascript';
    googleTranslateScript.src =
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.body.appendChild(googleTranslateScript);
  }

  ngOnDestroy(): void {
    this.subscription.forEach((subscription : Subscription) => {
      subscription.unsubscribe();
    })
    this.showFiller = null;
    this.userProfile = null;
    this.profileImagePath = null;
    this.selectedLanguage = null;
  }

  getLanguageList() {
    this.login.fetchLanguages();
    this.login.getLanguageList().subscribe({
      next: (languages) => {
        this.languageList = languages;
        this.selectedLanguage = languages.find(
          (lang) => lang.languageCode === this.userProfile?.languageCode
        );
        this.authService.setLoader(false);
      },
      error: (error) => {
        console.log('----error----', error);
      },
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  changeLanguage(lang: any): void {
    const userId = Number(this.storage.getObject(StorageItemType.USER_ID));

    this.subscription.push(
    this.login.updateLanguage(userId, lang?.languageId).subscribe({
      next: (res: any) => {
        if(res?.success){

          this.subscription.push(
          this.translateService.getTranslation(lang?.languageCode).subscribe({
            next: (res: any) => {},
            error: (error) => {
              console.log('----error----', error);
            }
          })
        )
   
          this.userProfile.languageCode = lang?.languageCode
          this.userProfile.languageName = lang?.languageDesc

          this.storage.setObject(StorageItemType.USER_PROFILE, JSON.stringify(this.userProfile))

          this.translate.use(lang?.languageCode);
          this.selectedLanguage = lang;
  
        } else {
          this.snackBar.openSnackBar(this.translate.instant('WRONG'), SnackBar.ERROR);
        }
      },
      error: (error) => {
        console.log('----error----', error);
      }
    })
  )
  }

  openSnackBar(message: string, panelClass: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: panelClass,
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  loginAs() {
    const dialogRef = this.dialog.open(LoginAsDialogComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  notifications: notifications[] = [
    {
      id: 1,
      img: '/assets/images/profile/user-1.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
    },
    {
      id: 2,
      img: '/assets/images/profile/user-2.jpg',
      title: 'New message received',
      subtitle: 'Salma sent you new message',
    },
    {
      id: 3,
      img: '/assets/images/profile/user-3.jpg',
      title: 'New Payment received',
      subtitle: 'Check your earnings',
    },
    {
      id: 4,
      img: '/assets/images/profile/user-4.jpg',
      title: 'Jolly completed tasks',
      subtitle: 'Assign her new tasks',
    },
    {
      id: 5,
      img: '/assets/images/profile/user-5.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
    },
  ];

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'My Profile',
      subtitle: 'Account Settings',
      link: '/web/account-setting',
    },
    // {
    //   id: 2,
    //   img: '/assets/images/svgs/icon-inbox.svg',
    //   title: 'My Inbox',
    //   subtitle: 'Messages & Email',
    //   link: '/apps/email/inbox',
    // },
    // {
    //   id: 3,
    //   img: '/assets/images/svgs/icon-tasks.svg',
    //   title: 'My Tasks',
    //   subtitle: 'To-do and Daily Tasks',
    //   link: '/apps/taskboard',
    // },
  ];

  apps: apps[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-dd-chat.svg',
      title: 'Chat Application',
      subtitle: 'Messages & Emails',
      link: '/apps/chat',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-dd-cart.svg',
      title: 'Todo App',
      subtitle: 'Completed task',
      link: '/apps/todo',
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-dd-invoice.svg',
      title: 'Invoice App',
      subtitle: 'Get latest invoice',
      link: '/apps/invoice',
    },
    {
      id: 4,
      img: '/assets/images/svgs/icon-dd-date.svg',
      title: 'Calendar App',
      subtitle: 'Get Dates',
      link: '/apps/calendar',
    },
    {
      id: 5,
      img: '/assets/images/svgs/icon-dd-mobile.svg',
      title: 'Contact Application',
      subtitle: '2 Unsaved Contacts',
      link: '/apps/contacts',
    },
    {
      id: 6,
      img: '/assets/images/svgs/icon-dd-lifebuoy.svg',
      title: 'Tickets App',
      subtitle: 'Create new ticket',
      link: '/apps/tickets',
    },
    {
      id: 7,
      img: '/assets/images/svgs/icon-dd-message-box.svg',
      title: 'Email App',
      subtitle: 'Get new emails',
      link: '/apps/email/inbox',
    },
    {
      id: 8,
      img: '/assets/images/svgs/icon-dd-application.svg',
      title: 'Courses',
      subtitle: 'Create new course',
      link: '/apps/courses',
    },
  ];

  quicklinks: quicklinks[] = [
    {
      id: 1,
      title: 'Pricing Page',
      link: '/theme-pages/pricing',
    },
    {
      id: 2,
      title: 'Authentication Design',
      link: '/authentication/side-login',
    },
    {
      id: 3,
      title: 'Register Now',
      link: '/authentication/side-register',
    },
    {
      id: 4,
      title: '404 Error Page',
      link: '/authentication/error',
    },
    {
      id: 5,
      title: 'Notes App',
      link: '/apps/notes',
    },
    {
      id: 6,
      title: 'Employee App',
      link: '/apps/employee',
    },
    {
      id: 7,
      title: 'Todo Application',
      link: '/apps/todo',
    },
    {
      id: 8,
      title: 'Treeview',
      link: '/theme-pages/treeview',
    },
  ];

  logOut() {
    const isLoginRole = this.storage.getObject(StorageItemType.IS_ADMIN);
    if(isLoginRole){
      const adminId = this.storage.getObject(StorageItemType.ADMIN_LOGIN_ID);
      this.userService.loginAs(Number(adminId)).subscribe({
        next: (res) => {
          if(res?.data) {
            this.login.setUser(res, adminId);
          }
        },
        error: (error) => {
          console.log('---error--', error);
        }
      });

    } else {
      this.login.logOut();
    }
  }
}

@Component({
  selector: 'search-dialog',
  standalone: true,
  imports: [
    RouterModule,
    MaterialModule,
    TablerIconsModule,
    FormsModule,
    NgForOf,
  ],
  templateUrl: 'search-dialog.component.html',
})
export class AppSearchDialogComponent {
  searchText: string = '';
  navItems = navItems;

  navItemsData = navItems.filter((navitem) => navitem.displayName);

  // filtered = this.navItemsData.find((obj) => {
  //   return obj.displayName == this.searchinput;
  // });
}
