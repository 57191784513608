import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, catchError, filter, finalize, switchMap, take, throwError } from 'rxjs';
import { LoginService } from '../services/login.service';
import { StorageService } from '../services/storage.service';
import { StorageItemType } from '../constants/storageitem';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshToken;
  private totalRequests = 0;

  constructor(
    private loginService: LoginService,
    private storage: StorageService,
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Get token from local storage
    this.totalRequests++
    this.authService.setLoader(true)
    const token = JSON.parse(this.storage.getObject(StorageItemType.TOKEN)) || '';
    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log("Inside token error i.e. when access token expired.");
        // Check error status code
        if (error.status === 401) {
          if (error.error != null && error.error.errors.length > 0) {
            var message = error.error.errors[0].errorMessage;
            if (message === 'REFRESH_TOKEN_REQUIRED') {
              // Log out user if refreshToken found null or empty.
              this.loginService.logOut();
              this.authService.setLoader(false);
              return EMPTY;
            }
          }

          console.log("Status Code 401");
          this.authService.setLoader(false);
          // If 401, i.e. unauthorized, so need to handle token expiry and get new token using refresh token
          return this.handle401Error(request, next);
        } else {
          return throwError(() => error);
        }
      }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.authService.setLoader(false)
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      },
      withCredentials: true
    });
  }

  // Handle 401 error
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("Inside handle401Error");

    if (!this.isRefreshing) {

      // refresh token flow started
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // Disabled below code if we are using HTTP only cookies from browser directly in interceptor.
      // // get refresh token from storage and decrypt to send to refresh api call.
      this.refreshToken = this.storage.getObject(StorageItemType.REFRESH_TOKEN);
      // cleanup any unseen character codes.
      if (this.refreshToken != null) {
        this.refreshToken = this.refreshToken.replace(/^"(.*)"$/, '$1');
      }
      else if (this.refreshToken === null || this.refreshToken === '') {
        console.log("Logout because refresh token is null or empty.");

        // Log out user if refreshToken found null or empty.
        this.authService.setLoader(false);
        this.loginService.logOut();
        return EMPTY;
      }

      // API call to get new token and refresh token
      return this.loginService.refreshToken({ refreshToken: this.refreshToken }).pipe(
        switchMap((tokenResponse: any) => {
          console.log('Refresh Token Received:', tokenResponse);

          // Validate if we have received token details or not
          if (!tokenResponse.isNull && tokenResponse.data != null && tokenResponse.data.token != null) {

            // Save token in storage
            this.storage.setObject(StorageItemType.TOKEN, tokenResponse.data.token);

            // Disabled below code if we are using HTTP only cookies from browser directly in interceptor.
            // Save refresh token in local storage
            this.storage.setObject(StorageItemType.REFRESH_TOKEN, tokenResponse.data.refreshToken);

            // after successful receiving new tokens set isRefreshing to false so that refresh token flow execution will stop.
            this.isRefreshing = false;
            this.refreshTokenSubject.next(tokenResponse.data.token);

          } else {
            this.loginService.logOut();
            return EMPTY;
          }

          // Handle request with new token
          return next.handle(this.addToken(request, tokenResponse.data.token));

        }),
        catchError((error) => {
          console.error('Refresh token expired:', error);

          // If error while refreshing token then logout the user.
          this.isRefreshing = false;
          this.loginService.logOut();
          return throwError(() => error);
        })
      );

    } else {

      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        })
      );

    }
  }

}